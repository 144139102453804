<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    :items="bannerTypeList"
                    v-model="bannerTypeSelected"
                    :label="$t('evoucher.issuance.type')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    :items="bannerStatusList"
                    v-model="bannerStatusSelected"
                    :label="$t('evoucher.issuance.status')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('advertisementInfo.list')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddAdvertisement(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="advertisementHeader"
            :items="advertisementInfoList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td>
                <img
                  :src="item.imageUrl ? item.imageUrl : noImg"
                  width="60"
                  height="40"
                  style="border: 2px solid #fff;">
              </td>
              <td>{{ item.title }}</td>
              <td style="max-width: 250px;">{{ item.description }}</td>
              <td style="max-width: 250px;">
                <span :style="`padding: 5px; background-color: ${item.status == bannerStatus.PREPARE ? '#ffa21a' : (item.status == bannerStatus.ACTIVE ? '#4caf50' : '#f44336')}; color: #fff; border-radius: 5px;`">{{ $t(getStatus(item.status)) }}</span>
              </td>
              <td style="max-width: 250px;">{{ $t(getType(item.type)) }}</td>
              <td>
                <v-menu
                  v-model="item.selectedButton"
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <!-- Update supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="warning"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="(item.status == bannerStatus.PREPARE || item.status == bannerStatus.CLOSE) ? onShowModalPublicBanner(item) : onShowModalInactiveBanner(item)">
                          <v-icon
                            v-if="(item.status == bannerStatus.PREPARE || item.status == bannerStatus.CLOSE)"
                            dark>mdi-check-underline</v-icon>
                          <v-icon
                            v-else-if="item.status != bannerStatus.PREPARE && item.status != bannerStatus.CLOSE"
                            dark>mdi-close-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ (item.status == bannerStatus.PREPARE || item.status == bannerStatus.CLOSE) ? $t('advertisementInfo.status.public') : $t('advertisementInfo.status.inactive') }}</span>
                    </v-tooltip>
                    <!-- Update supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalAddAdvertisement(item.id)">
                          <v-icon dark>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.update') }}</span>
                    </v-tooltip>
                    <!-- Delete supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalDeleteAdvertisement(item.id)">
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.delete') }}</span>
                    </v-tooltip>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="advertisementPaginate.totalPage"
      :current-page="advertisementPaginate.currentPage"
      :row-per-page="advertisementPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <addAdvertisementModal
      ref="modalAddAdvertisement"
      @addAdvertisementSuccess="onAddAdvertisementSuccess"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="actionSelected == actionType.PREPARE ? onInactiveBanner() : (actionSelected == actionType.ACTIVE ? onPublicBanner() : onDeleteAdvertisement())"
    />
    <!-- <userDetailModal ref="userDetailModal" /> -->
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmModal from 'Components/ConfirmModal'
import AddAdvertisementModal from './ModalAddAdvertisementInfo'
import ToastType from 'enum/toastType'
import bannerType from 'enum/bannerType'
import bannerStatus from 'enum/bannerStatus'
import stringUtils from 'utils/stringUtils'
export default {
  components: {
    ConfirmModal,
    AddAdvertisementModal
  },
  data: () => ({
    advertisementHeader: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'common.image',
        value: 'imageUrl'
      },
      {
        sortable: false,
        text: 'advertisementInfo.title',
        value: 'title'
      },
      {
        sortable: false,
        text: 'advertisementInfo.description',
        value: 'description'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.type',
        value: 'type'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    advertisementInfoList: [],
    advertisementPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    confirmModalTitle: '',
    userSearch: {
      userName: null,
      userFullName: null,
      userPhone: null,
      userGender: null,
      userEmail: null
    },
    isLoading: false,
    noImg: require('../../assets/noimage.png'),
    advertisementInfoIdSelected: null,
    bannerTypeList: [{ text: '---', value: null }, ...stringUtils.BannerTypeList],
    bannerTypeSelected: null,
    bannerStatusList: [{ text: '---', value: null }, ...stringUtils.BannerStatusList],
    bannerStatusSelected: null,
    bannerStatus: {
      PREPARE: bannerStatus.PREPARE,
      ACTIVE: bannerStatus.PUBLIC,
      CLOSE: bannerStatus.CLOSE
    },
    actionType: {
      ACTIVE: 1,
      PREPARE: 2,
      DELETE_BANNER: 3
    },
    actionSelected: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'LIST_ADVERTISEMENT_INFO_DATA'
    ])
  },
  watch: {
    LIST_ADVERTISEMENT_INFO_DATA () {
      let res = this.LIST_ADVERTISEMENT_INFO_DATA
      // Handle paginate
      this.advertisementPaginate.currentPage = res.paginate.currentPage
      this.advertisementPaginate.totalPage = res.paginate.totalPage
      this.advertisementPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle data
      let data = res.results
      this.advertisementInfoList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let advertisementInfoObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: data[i].id,
          title: data[i].title,
          description: data[i].description,
          imageUrl: data[i].image_url,
          selectedButton: false,
          type: data[i].type,
          status: data[i].status
        }
        this.advertisementInfoList.push(advertisementInfoObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getAdvertisementInfoList()
  },
  methods: {
    /**
     * To do In active banner
     */
    onInactiveBanner: function () {
      let filter = {
        id: this.advertisementInfoIdSelected
      }
      this.TO_DO_INACTIVE_BANNER(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getAdvertisementInfoList()
        }.bind(this)
      )
    },
    /**
     * Show modal Inactive banner
     */
    onShowModalInactiveBanner: function (item) {
      this.actionSelected = this.actionType.PREPARE
      this.advertisementInfoIdSelected = item.id
      this.confirmModalTitle = this.$t('advertisementInfo.confirmModalTitle.prepare')
      this.$refs.confirmModal.onShowModal(item.id)
    },
    /**
     * Show modal Public banner
     */
    onShowModalPublicBanner: function (item) {
      this.actionSelected = this.actionType.ACTIVE
      this.advertisementInfoIdSelected = item.id
      this.confirmModalTitle = this.$t('advertisementInfo.confirmModalTitle.active')
      this.$refs.confirmModal.onShowModal(item.id)
    },
    /**
     * To do Public banner
     */
    onPublicBanner: function (item) {
      let filter = {
        id: this.advertisementInfoIdSelected
      }
      this.PUBLIC_BANNER(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getAdvertisementInfoList()
        }.bind(this)
      )
    },
    /**
    * Type
    */
    getType: function (type) {
      if (bannerType.SMALL === type) {
        return 'advertisementInfo.type.small'
      } else if (bannerType.LARGE === type) {
        return 'advertisementInfo.type.large'
      } else if (bannerType.POPUP === type) {
        return 'advertisementInfo.type.popupLearning'
      } else {
        return ''
      }
    },
    /**
     * Get banner status
     */
    getStatus: function (status) {
      if (status === bannerStatus.PREPARE) {
        return 'advertisementInfo.status.prepare'
      } else if (status === bannerStatus.PUBLIC) {
        return 'advertisementInfo.status.public'
      } else if (status === bannerStatus.CLOSE) {
        return 'advertisementInfo.status.close'
      } else {
        return ''
      }
    },
    /**
     * Add advertisement success
     */
    onAddAdvertisementSuccess: function () {
      this.getAdvertisementInfoList()
    },
    /**
     * Delete advertisement
     */
    onDeleteAdvertisement: function () {
      this.DELETE_ADVERTISEMENT_INFO({ id: this.advertisementInfoIdSelected }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getAdvertisementInfoList()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            'styleType': ToastType.ERROR
          })
        }
      )
    },
    /**
     * Search
     */
    onSearch: function () {
      this.getAdvertisementInfoList()
    },
    /**
     * Show modal add user
     */
    onShowModalAddAdvertisement: function (advertisementId) {
      this.$refs.modalAddAdvertisement.onShowModal(advertisementId)
    },
    /**
     * Show modal delete user
     */
    onShowModalDeleteAdvertisement: function (advertisementId) {
      this.actionSelected = this.actionType.DELETE_BANNER
      this.advertisementInfoIdSelected = advertisementId
      this.confirmModalTitle = this.$t('advertisementInfo.confirmDeleteAds')
      this.$refs.confirmModal.onShowModal(advertisementId)
    },
    /**
     * Get advertisement list
     */
    getAdvertisementInfoList: function () {
      let filter = {
        params: {
          type: this.bannerTypeSelected,
          status: this.bannerStatusSelected,
          page: this.advertisementPaginate.currentPage
        }
      }
      this.isLoading = true
      this.GET_LIST_ADVERTISEMENT_INFO(filter)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.advertisementPaginate.currentPage = page
      this.getAdvertisementInfoList()
    },
    ...mapActions([
      'GET_LIST_ADVERTISEMENT_INFO',
      'ON_SHOW_TOAST',
      'DELETE_ADVERTISEMENT_INFO',
      'PUBLIC_BANNER',
      'TO_DO_INACTIVE_BANNER'
    ])
  }
}
</script>
<style lang="scss" scoped>
</style>
