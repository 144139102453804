<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="advertisementInfo.id ? $t('advertisementInfo.updateAds') : $t('advertisementInfo.addNewAds')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <!-- Title -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="advertisementInfo.title"
                  :label="$t('advertisementInfo.title')"
                  :name="$t('advertisementInfo.title')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                md6>
                <v-select
                  :items="bannerTypeList"
                  v-model="bannerTypeSelected"
                  :label="$t('evoucher.issuance.type')"
                  @change="onChangeBannerType"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-layout wrap>
                <!-- Issuance img -->
                <v-flex
                  class="text-center"
                  xs12
                  md6
                >
                  <image-picker
                    key="banner-img"
                    :image-width="width"
                    :image-height="height"
                    :image-url="advertisementInfo.imageUrl"
                    :exceptation-ratio="exceptationImageRatio.value"
                    :max-size-upload="maxSizeUpload.value"
                    :recommend-dimension="recommendImageDimension.value"
                    @onSelectedImageFile="onSelectBannerImg"
                    @onResetImage="onClickRemoveBannerImg"
                  />
                </v-flex>
                <!-- Description -->
                <v-flex
                  xs12
                  md6>
                  <v-textarea
                    v-model="advertisementInfo.description"
                    :label="$t('advertisementInfo.description')"
                    :name="$t('advertisementInfo.description')"
                    type="text"
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { uploadFileToMediaAgent } from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import stringUtils from 'utils/stringUtils'
import validationRules from 'utils/validationRules'
import bannerType from 'enum/bannerType'
import ImagePicker from 'Components/ImagePicker.vue'

export default {
  components: { ImagePicker },
  data () {
    return {
      valid: false,
      isShowModal: false,
      advertisementInfo: {
        id: null,
        title: null,
        imageUrl: null,
        description: null,
        imageFile: null,
        imageSize: null
      },
      bannerTypeList: stringUtils.BannerTypeList,
      bannerTypeSelected: bannerType.SMALL,
      guideImageText: null,
      width: null,
      height: null,
      exceptationImageRatio: {
        value: ''
      },
      maxSizeUpload: {
        value: ''
      },
      recommendImageDimension: {
        value: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {},
  created () {
    this.getImageGuide()
  },
  mounted () {},
  methods: {
    /**
     * Change banner type
     */
    onChangeBannerType: function () {
      this.getImageGuide()
    },
    /**
     * Get image guide
     */
    getImageGuide: function () {
      if (this.bannerTypeSelected === bannerType.SMALL || this.bannerTypeSelected === bannerType.LARGE) {
        this.exceptationImageRatio.value = '16:9'
        this.maxSizeUpload.value = '10MB'
        if (this.bannerTypeSelected === bannerType.SMALL) {
          this.recommendImageDimension.value = '1200x210'
        } else {
          this.recommendImageDimension.value = '1200x435'
        }
      } else if (this.bannerTypeSelected === bannerType.POPUP) {
        this.exceptationImageRatio.value = '3:4'
        this.maxSizeUpload.value = '3MB'
        this.recommendImageDimension.value = '600x800'
      }
    },
    /**
     * Upload advertisement image
     */
    uploadBannerImage: async function () {
      this.advertisementInfo.imageUrl = await uploadFileToMediaAgent(this.advertisementInfo.imageFile, 'eVoucher/BannerAdvertisement')
    },
    /**
     * Select img
     */
    onSelectBannerImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.advertisementInfo.imageSize = files[0].size
      this.getResultFile(files[0])
      this.advertisementInfo.imageUrl = URL.createObjectURL(files[0])
    },
    /**
     * Remove img
     */
    onClickRemoveBannerImg: function () {
      this.advertisementInfo.imageUrl = null
      this.advertisementInfo.imageFile = null
    },
    getResultFile: function (file) {
      let vm = this
      vm.advertisementInfo.imageFile = file
    },
    /**
     * Require value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Show modal
     */
    onShowModal: function (advertisementId) {
      this.setDefaultValue()
      if (advertisementId) {
        this.advertisementInfo.id = advertisementId
        this.GET_ADVERTISEMENT_INFO_DETAIL({ id: advertisementId }).then(
          function (res) {
            let data = res.data
            this.advertisementInfo.title = data.title
            this.advertisementInfo.description = data.description
            this.advertisementInfo.imageUrl = data.image_url
            this.advertisementInfo.bannerTypeSelected = data.type
          }.bind(this)
        )
      }
      this.isShowModal = true
    },
    /**
     * Set default value
     */
    setDefaultValue: function () {
      this.advertisementInfo.id = null
      this.advertisementInfo.title = null
      this.advertisementInfo.description = null
      this.advertisementInfo.imageUrl = null
      this.advertisementInfo.imageFile = null
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: async function () {
      if (this.$refs.form.validate()) {
        if ((this.bannerTypeSelected === bannerType.SMALL || this.bannerTypeSelected === bannerType.LARGE) && this.advertisementInfo.imageSize > 10000000) {
          this.ON_SHOW_TOAST({
            message: this.$t('common.imageCannotExceed10MB'),
            styleType: 'danger'
          })
          return
        } else if (this.bannerTypeSelected === bannerType.POPUP && this.advertisementInfo.imageSize > 3000000) {
          this.ON_SHOW_TOAST({
            message: this.$t('common.imageCannotExceed3MB'),
            styleType: 'danger'
          })
          return
        }
        let filter = {
          title: this.advertisementInfo.title,
          imageUrl: this.advertisementInfo.imageUrl,
          description: this.advertisementInfo.description,
          status: null,
          validDate: null,
          isPublic: false,
          type: this.bannerTypeSelected
        }
        if (this.advertisementInfo.imageFile) {
          await this.uploadBannerImage()
          filter['imageUrl'] = this.advertisementInfo.imageUrl
        }
        if (this.advertisementInfo.id) {
          this.UPDATE_ADVERTISEMENT_INFO({ id: this.advertisementInfo.id, ...filter }).then(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.isShowModal = false
              this.$emit('addAdvertisementSuccess')
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          )
        } else {
          this.ADD_ADVERTISEMENT_INFO(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.isShowModal = false
              this.$emit('addAdvertisementSuccess')
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          )
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'ADD_ADVERTISEMENT_INFO',
      'GET_ADVERTISEMENT_INFO_DETAIL',
      'UPDATE_ADVERTISEMENT_INFO'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
